/* eslint-disable */
import React, { useState, useEffect } from "react";
import { getAllUsers, updateAdminRole } from "functions/functions";
import Userfront from "@userfront/core";
import AdminDropdown from "./AdminDropdown";
import "pages/AdminDashboard/AdminOptions.css";

function AdminDashboard() {
  const [selectedMenu, setSelectedMenu] = useState("userRoles");
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const users = await getAllUsers();
        setUserData(users.filter((user) => user.is_maker === true));
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  function determineUserRole(user) {
    const role = user.is_senior_admin
      ? "Senior Admin"
      : user.is_junior_admin
        ? "Junior Admin"
        : user.is_organizational_admin
          ? "Organization Admin"
          : user.is_admin
            ? "Admin"
            : user.is_maker
              ? "Maker"
              : "Unknown Role";
    return role;
  }

  const handleRoleChange = async (selectedRole, userUuid) => {
    const confirmChange = window.confirm(
      `Are you sure you want to change this user's role to ${selectedRole}?`,
    );

    if (!confirmChange) {
      return;
    }

    let isJuniorAdmin = false;
    let isSeniorAdmin = false;
    let isOrganizationAdmin = false;
    let isAdmin = false;
    let isMaker = false;

    switch (selectedRole) {
      case "Junior Admin":
        isJuniorAdmin = true;
        isMaker = true;
        break;
      case "Senior Admin":
        isSeniorAdmin = true;
        isMaker = true;
        break;
      case "Organization Admin":
        isOrganizationAdmin = true;
        isMaker = true;
        break;
      case "Admin":
        isAdmin = true;
        isMaker = true;
        break;
      case "Maker":
        isMaker = true;
        break;
      default:
        console.error("Invalid role selected");
        return;
    }

    try {
      const selectedUser = userData.find((user) => user.uuid === userUuid);
      if (!selectedUser) {
        console.error("User not found");
        return;
      }

      await updateAdminRole(
        userUuid,
        selectedUser.first_name,
        selectedUser.last_name,
        selectedUser.email,
        isJuniorAdmin,
        isSeniorAdmin,
        isOrganizationAdmin,
        isAdmin,
        isMaker,
      );

      setUserData(
        userData.map((user) =>
          user.uuid === userUuid
            ? {
                ...user,
                is_junior_admin: isJuniorAdmin,
                is_senior_admin: isSeniorAdmin,
                is_organizational_admin: isOrganizationAdmin,
                is_admin: isAdmin,
                is_maker: isMaker,
              }
            : user,
        ),
      );
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const handlePasswordReset = async (email) => {
    Userfront.init("9nywgrgn", {
      domain: "https://jackrabt.com"
    });

    try {
      const result = await Userfront.sendResetLink(email);
      console.log(result);
      alert(`Password reset email sent to ${email}`);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Failed to send password reset email.");
    }
  };

  const handleRowToggle = (userUuid) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(userUuid)
        ? prevExpandedRows.filter((uuid) => uuid !== userUuid)
        : [...prevExpandedRows, userUuid],
    );
  };

  const filteredUsers = userData.filter((user) =>
    `${user.first_name} ${user.last_name} ${user.email}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase()),
  );

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <div className="admin-dashboard">
      <div className="sidebar">
        <ul>
          <li className={selectedMenu === "userRoles" ? "active" : ""}>
            <button
              type="button"
              onClick={() => handleMenuClick("userRoles")}
              onKeyDown={(e) =>
                e.key === "Enter" && handleMenuClick("userRoles")
              }
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                textAlign: "left",
                width: "100%",
              }}
            >
              User Roles & Permissions
            </button>
          </li>
          <li className={selectedMenu === "listings" ? "active" : ""}>
            <button
              type="button"
              onClick={() => handleMenuClick("listings")}
              onKeyDown={(e) =>
                e.key === "Enter" && handleMenuClick("listings")
              }
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                textAlign: "left",
                width: "100%",
              }}
            >
              Listings
            </button>
          </li>
        </ul>
      </div>

      <div className="main-content">
        {selectedMenu === "userRoles" && (
          <div>
            <h2 style={{ textAlign: "center" }}>User Roles & Permissions</h2>
            <div
              className="container user-info-box"
              style={{ marginTop: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    maxWidth: "600px",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search by Name or Email"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      border: "2px solid #ff7708",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                </div>

                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div className="spinner" />
                  </div>
                ) : (
                  <table
                    className="user-table"
                    style={{
                      width: "80%",
                      maxWidth: "800px",
                      borderCollapse: "collapse",
                      textAlign: "center",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user) => (
                        <React.Fragment key={user.uuid}>
                          <tr
                            onClick={() => handleRowToggle(user.uuid)}
                            onKeyDown={(e) =>
                              e.key === "Enter" && handleRowToggle(user.uuid)
                            }
                            tabIndex={0}
                            role="button"
                          >
                            <td>{`${user.first_name} ${user.last_name}`}</td>
                            <td>{user.email}</td>
                            <td>
                              <AdminDropdown
                                label=""
                                options={[
                                  {
                                    label: "Senior Admin",
                                    value: "Senior Admin",
                                  },
                                  {
                                    label: "Junior Admin",
                                    value: "Junior Admin",
                                  },
                                  {
                                    label: "Organization Admin",
                                    value: "Organization Admin",
                                  },
                                  { label: "Admin", value: "Admin" },
                                  { label: "Maker", value: "Maker" },
                                ]}
                                value={determineUserRole(user)}
                                userId={user.uuid}
                                onRoleChange={handleRoleChange}
                              />
                            </td>
                          </tr>
                          {expandedRows.includes(user.uuid) && (
                            <tr>
                              <td
                                colSpan={3}
                                style={{ textAlign: "left", padding: "10px" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handlePasswordReset(user.email)
                                  }
                                  className="button-primary"
                                >
                                  Reset Password
                                </button>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}

        {selectedMenu === "listings" && (
          <div style={{ textAlign: "center", paddingTop: "100px" }}>
            <h2>Listings</h2>
            <p>This section is under development.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
