import React from "react";

const AdminDropdown = ({ label, options, value, userId, onRoleChange }) => {
  const handleChange = (event) => {
    onRoleChange(event.target.value, userId);
  };

  return (
    <label
      style={{
        borderRadius: "6px",
        padding: "1px",
        marginRight: "10px",
      }}
    >
      {label}
      <select
        value={value}
        onChange={handleChange}
        style={{
          borderRadius: "6px",
          padding: "5px",
          marginLeft: "-3px",
          border: "1px solid #ccc",
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default AdminDropdown;
