export const trashSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6H5H21"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11V17"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11V17"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const calendarSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 27.0827C17.8173 27.0827 18.7501 26.1499 18.7501 24.9994C18.7501 23.8488 17.8173 22.916 16.6667 22.916C15.5162 22.916 14.5834 23.8488 14.5834 24.9994C14.5834 26.1499 15.5162 27.0827 16.6667 27.0827Z"
        fill="#484848"
      />
      <path
        d="M27.0834 24.9994C27.0834 26.1499 26.1507 27.0827 25.0001 27.0827C23.8495 27.0827 22.9167 26.1499 22.9167 24.9994C22.9167 23.8488 23.8495 22.916 25.0001 22.916C26.1507 22.916 27.0834 23.8488 27.0834 24.9994Z"
        fill="#484848"
      />
      <path
        d="M33.3334 27.0827C34.484 27.0827 35.4167 26.1499 35.4167 24.9994C35.4167 23.8488 34.484 22.916 33.3334 22.916C32.1828 22.916 31.2501 23.8488 31.2501 24.9994C31.2501 26.1499 32.1828 27.0827 33.3334 27.0827Z"
        fill="#484848"
      />
      <path
        d="M18.7501 33.3327C18.7501 34.4833 17.8173 35.416 16.6667 35.416C15.5162 35.416 14.5834 34.4833 14.5834 33.3327C14.5834 32.1821 15.5162 31.2494 16.6667 31.2494C17.8173 31.2494 18.7501 32.1821 18.7501 33.3327Z"
        fill="#484848"
      />
      <path
        d="M25.0001 35.416C26.1507 35.416 27.0834 34.4833 27.0834 33.3327C27.0834 32.1821 26.1507 31.2494 25.0001 31.2494C23.8495 31.2494 22.9167 32.1821 22.9167 33.3327C22.9167 34.4833 23.8495 35.416 25.0001 35.416Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 6.24935C16.6667 5.09876 15.734 4.16602 14.5834 4.16602C13.4328 4.16602 12.5001 5.09876 12.5001 6.24935H8.33342C6.03223 6.24935 4.16675 8.11483 4.16675 10.416V39.5827C4.16675 41.8839 6.03223 43.7494 8.33342 43.7494H41.6668C43.9679 43.7494 45.8334 41.8839 45.8334 39.5827V10.416C45.8334 8.11483 43.9679 6.24935 41.6668 6.24935H37.5001C37.5001 5.09876 36.5673 4.16602 35.4167 4.16602C34.2662 4.16602 33.3334 5.09876 33.3334 6.24935H16.6667ZM8.33342 14.5827V10.416H41.6668V14.5827H8.33342ZM8.33342 18.7493V39.5827H29.5243L41.6668 27.4402V18.7493H8.33342ZM41.6668 33.3327L35.4168 39.5827H41.6668V33.3327Z"
        fill="#484848"
      />
    </svg>
  );
};

export const messagesSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5001 16.6667C12.5001 15.5161 13.4328 14.5833 14.5834 14.5833H35.4167C36.5673 14.5833 37.5001 15.5161 37.5001 16.6667C37.5001 17.8173 36.5673 18.75 35.4167 18.75H14.5834C13.4328 18.75 12.5001 17.8173 12.5001 16.6667Z"
        fill="#484848"
      />
      <path
        d="M14.5834 22.9167C13.4328 22.9167 12.5001 23.8494 12.5001 25C12.5001 26.1506 13.4328 27.0833 14.5834 27.0833H22.9167C24.0673 27.0833 25.0001 26.1506 25.0001 25C25.0001 23.8494 24.0673 22.9167 22.9167 22.9167H14.5834Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5001 35.4167H41.6668C43.9679 35.4167 45.8334 33.5512 45.8334 31.25V10.4167C45.8334 8.11548 43.9679 6.25 41.6668 6.25H8.33342C6.03223 6.25 4.16675 8.11548 4.16675 10.4167V31.25C4.16675 33.5512 6.03223 35.4167 8.33341 35.4167H25.0001V39.5833C25.0001 43.5876 30.0976 45.2867 32.5001 42.0833L37.5001 35.4167ZM29.1667 31.25V39.5833L35.4167 31.25H41.6668V10.4167H8.33342V31.25H29.1667Z"
        fill="#484848"
      />
    </svg>
  );
};

export const manyMessagesSvg = () => {
  return (
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3333 18.6667H28C29.4728 18.6667 30.6667 17.4728 30.6667 16V2.66667C30.6667 1.19391 29.4728 0 28 0H6.66667C5.19391 0 4 1.19391 4 2.66667V4.5C4 5.97276 5.19391 6.66667 6.66667 6.66667L15.5 6.5H24C24 9.0627 24 19 24 18.6667H25.3333ZM15.5 6.5H24V16H28V2.66667H6.66667L6.66667 6.66667L15.5 6.5Z"
        fill="#484848"
      />
      <path
        d="M5.33333 10.6667C5.33333 9.93029 5.93029 9.33333 6.66667 9.33333H20C20.7364 9.33333 21.3333 9.93029 21.3333 10.6667C21.3333 11.403 20.7364 12 20 12H6.66667C5.93029 12 5.33333 11.403 5.33333 10.6667Z"
        fill="#484848"
      />
      <path
        d="M6.66667 14.6667C5.93029 14.6667 5.33333 15.2636 5.33333 16C5.33333 16.7364 5.93029 17.3333 6.66667 17.3333H12C12.7364 17.3333 13.3333 16.7364 13.3333 16C13.3333 15.2636 12.7364 14.6667 12 14.6667H6.66667Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 22.6667H24C25.4728 22.6667 26.6667 21.4728 26.6667 20V6.66667C26.6667 5.19391 25.4728 4 24 4H2.66667C1.19391 4 0 5.19391 0 6.66667V20C0 21.4728 1.19391 22.6667 2.66667 22.6667H13.3333V25.3333C13.3333 27.896 16.5957 28.9835 18.1333 26.9333L21.3333 22.6667ZM16 20V25.3333L20 20H24V6.66667H2.66667V20H16Z"
        fill="#484848"
      />
    </svg>
  );
};
export const listingSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0834 14.5827C27.0834 13.4321 26.1507 12.4993 25.0001 12.4993C23.8495 12.4993 22.9167 13.4321 22.9167 14.5827V24.9994C22.9167 26.1499 23.8495 27.0827 25.0001 27.0827H33.3334C34.484 27.0827 35.4167 26.1499 35.4167 24.9994C35.4167 23.8488 34.484 22.916 33.3334 22.916H27.0834V14.5827Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8334 24.9994C45.8334 36.5053 36.506 45.8327 25.0001 45.8327C13.4941 45.8327 4.16675 36.5053 4.16675 24.9994C4.16675 13.4934 13.4941 4.16602 25.0001 4.16602C36.506 4.16602 45.8334 13.4934 45.8334 24.9994ZM41.6668 24.9994C41.6668 34.2041 34.2048 41.666 25.0001 41.666C15.7953 41.666 8.33342 34.2041 8.33342 24.9994C8.33342 15.7946 15.7953 8.33268 25.0001 8.33268C34.2048 8.33268 41.6668 15.7946 41.6668 24.9994Z"
        fill="#484848"
      />
    </svg>
  );
};

export const reservationsSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0834 14.5827C27.0834 13.4321 26.1507 12.4993 25.0001 12.4993C23.8495 12.4993 22.9167 13.4321 22.9167 14.5827V24.9994C22.9167 26.1499 23.8495 27.0827 25.0001 27.0827H33.3334C34.484 27.0827 35.4167 26.1499 35.4167 24.9994C35.4167 23.8488 34.484 22.916 33.3334 22.916H27.0834V14.5827Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8334 24.9994C45.8334 36.5053 36.506 45.8327 25.0001 45.8327C13.4941 45.8327 4.16675 36.5053 4.16675 24.9994C4.16675 13.4934 13.4941 4.16602 25.0001 4.16602C36.506 4.16602 45.8334 13.4934 45.8334 24.9994ZM41.6668 24.9994C41.6668 34.2041 34.2048 41.666 25.0001 41.666C15.7953 41.666 8.33342 34.2041 8.33342 24.9994C8.33342 15.7946 15.7953 8.33268 25.0001 8.33268C34.2048 8.33268 41.6668 15.7946 41.6668 24.9994Z"
        fill="#484848"
      />
    </svg>
  );
};

const adminValidationSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4478 22.5763L26.1987 34.8254L19.8016 28.4283L17.929 30.3008L26.1987 38.5706L42.0714 22.6979L38.4478 22.5763ZM47.0711 7.00001V43.0001C47.0711 44.1046 46.1755 45.0001 45.0711 45.0001H4.92889C3.82438 45.0001 2.92889 44.1046 2.92889 43.0001V7.00001C2.92889 5.8955 3.82438 5.00001 4.92889 5.00001H45.0711C46.1755 5.00001 47.0711 5.8955 47.0711 7.00001ZM45.0711 4.00001C47.2093 4.00001 49.0711 5.86179 49.0711 8.00001V42.0001C49.0711 44.1383 47.2093 46.0001 45.0711 46.0001H4.92889C2.79067 46.0001 0.928894 44.1383 0.928894 42.0001V8.00001C0.928894 5.86179 2.79067 4.00001 4.92889 4.00001H45.0711Z"
        fill="#484848"
      />
    </svg>
  );
};

export default adminValidationSvg;

export const paymentPayoutSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8462 6.42956C25.3075 6.19015 24.6926 6.19015 24.154 6.42956L5.40396 14.7629C4.65161 15.0973 4.16675 15.8434 4.16675 16.6667C4.16675 17.49 4.65161 18.2361 5.40396 18.5704L10.4955 20.8333L5.40396 23.0962C4.65161 23.4306 4.16675 24.1767 4.16675 25C4.16675 25.8233 4.65161 26.5694 5.40396 26.9038L10.4955 29.1667L5.40396 31.4296C4.65161 31.7639 4.16675 32.51 4.16675 33.3333C4.16675 34.1566 4.65161 34.9027 5.40396 35.2371L24.154 43.5704C24.6926 43.8098 25.3075 43.8098 25.8462 43.5704L44.5962 35.2371C45.3486 34.9027 45.8334 34.1566 45.8334 33.3333C45.8334 32.51 45.3486 31.7639 44.5962 31.4296L39.5047 29.1667L44.5962 26.9038C45.3486 26.5694 45.8334 25.8233 45.8334 25C45.8334 24.1767 45.3486 23.4306 44.5962 23.0962L39.5047 20.8333L44.5962 18.5704C45.3486 18.2361 45.8334 17.49 45.8334 16.6667C45.8334 15.8434 45.3486 15.0973 44.5962 14.7629L25.8462 6.42956ZM34.3751 23.1132L25.8462 26.9038C25.3075 27.1432 24.6926 27.1432 24.154 26.9038L15.6251 23.1132L11.3797 25L25.0001 31.0535L38.6205 25L34.3751 23.1132ZM25.0001 22.7202L11.3797 16.6667L25.0001 10.6132L38.6205 16.6667L25.0001 22.7202ZM25.8462 35.2371L34.3751 31.4465L38.6205 33.3333L25.0001 39.3868L11.3797 33.3333L15.6251 31.4465L24.154 35.2371C24.6926 35.4765 25.3075 35.4765 25.8462 35.2371Z"
        fill="#484848"
      />
    </svg>
  );
};

export const notificationSvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0782 6.39631C27.0816 6.34776 27.0833 6.29876 27.0833 6.24935C27.0833 5.09876 26.1506 4.16602 25 4.16602C23.8494 4.16602 22.9167 5.09876 22.9167 6.24935C22.9167 6.29876 22.9184 6.34776 22.9218 6.39631C15.852 7.40501 10.4167 13.4841 10.4167 20.8327V35.416H8.33333C7.18274 35.416 6.25 36.3488 6.25 37.4994C6.25 38.6499 7.18274 39.5827 8.33333 39.5827H18.75C18.75 43.0345 21.5482 45.8327 25 45.8327C28.4518 45.8327 31.25 43.0345 31.25 39.5827H41.6667C42.8173 39.5827 43.75 38.6499 43.75 37.4994C43.75 36.3488 42.8173 35.416 41.6667 35.416H39.5833V20.8327C39.5833 13.4841 34.148 7.40501 27.0782 6.39631ZM35.4167 20.8327V35.416H14.5833V20.8327C14.5833 15.0797 19.247 10.416 25 10.416C30.753 10.416 35.4167 15.0797 35.4167 20.8327ZM25 41.666C23.8494 41.666 22.9167 40.7333 22.9167 39.5827H27.0833C27.0833 40.7333 26.1506 41.666 25 41.666Z"
        fill="#484848"
      />
    </svg>
  );
};

export const loginSecuritySvg = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9999 35.416C26.1505 35.416 27.0833 34.4833 27.0833 33.3327C27.0833 32.1821 26.1505 31.2493 24.9999 31.2493C23.8493 31.2493 22.9166 32.1821 22.9166 33.3327C22.9166 34.4833 23.8493 35.416 24.9999 35.416Z"
        fill="#484848"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5833 14.5827V20.8327H12.4999C10.1987 20.8327 8.33325 22.6982 8.33325 24.9993V41.666C8.33325 43.9672 10.1987 45.8327 12.4999 45.8327H37.4999C39.8011 45.8327 41.6666 43.9672 41.6666 41.666V24.9993C41.6666 22.6982 39.8011 20.8327 37.4999 20.8327H35.4166V14.5827C35.4166 8.82972 30.7529 4.16602 24.9999 4.16602C19.247 4.16602 14.5833 8.82972 14.5833 14.5827ZM24.9999 8.33268C21.5481 8.33268 18.7499 11.1309 18.7499 14.5827V20.8327H31.2499V14.5827C31.2499 11.1309 28.4517 8.33268 24.9999 8.33268ZM12.4999 24.9993V41.666H37.4999V24.9993H12.4999Z"
        fill="#484848"
      />
    </svg>
  );
};

export const dashboardSvg = () => {
  return (
    <div className="dropdown-icon">
      <svg
        width="33"
        height="33"
        viewBox="2 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5793 5.37128C10.6532 5.37128 5.83637 10.2042 5.83637 16.1818C5.83637 17.8769 6.22314 19.4772 6.91124 20.9015C7.23385 21.5693 6.95403 22.3722 6.28623 22.6948C5.61843 23.0174 4.81554 22.7376 4.49292 22.0698C3.63245 20.2887 3.15063 18.2898 3.15063 16.1818C3.15063 8.73509 9.1558 2.68555 16.5793 2.68555C24.0028 2.68555 30.008 8.73509 30.008 16.1818C30.008 18.2898 29.5261 20.2887 28.6657 22.0698C28.3431 22.7376 27.5402 23.0174 26.8724 22.6948C26.2046 22.3722 25.9247 21.5693 26.2474 20.9015C26.9355 19.4772 27.3222 17.8769 27.3222 16.1818C27.3222 10.2042 22.5054 5.37128 16.5793 5.37128Z"
          fill="#484848"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9519 21.9213C21.9503 20.7582 23.2936 18.5931 23.2936 16.1142C23.2936 12.406 20.2875 9.39988 16.5793 9.39988C12.8711 9.39988 9.86496 12.406 9.86496 16.1142C9.86496 18.5931 11.2083 20.7582 13.2067 21.9213C11.1512 22.4783 9.57874 23.4932 8.43818 24.5133C7.5951 25.2674 6.98949 26.0218 6.59151 26.593C6.39201 26.8793 6.2431 27.1219 6.14125 27.2986C6.09028 27.387 6.05095 27.4592 6.02283 27.5125C6.00877 27.5391 5.9975 27.561 5.98896 27.5778L5.9782 27.5993L5.97435 27.6071L5.9728 27.6102L5.97212 27.6116C5.97181 27.6123 5.9715 27.6129 7.17923 28.2L5.9715 27.6129C5.64726 28.2799 5.92513 29.0835 6.59214 29.4077C7.25734 29.7311 8.05835 29.4556 8.38431 28.7925L8.38507 28.791L8.3976 28.7669C8.41105 28.7414 8.43444 28.6982 8.46813 28.6398C8.53559 28.5227 8.64372 28.3457 8.79509 28.1284C9.09884 27.6924 9.57033 27.104 10.2286 26.5152C11.5324 25.3491 13.575 24.1714 16.5793 24.1714C19.5836 24.1714 21.6262 25.3491 22.9299 26.5152C23.5883 27.104 24.0597 27.6924 24.3635 28.1284C24.5149 28.3457 24.623 28.5227 24.6905 28.6398C24.7241 28.6982 24.7475 28.7414 24.761 28.7669L24.7735 28.791L24.7743 28.7925C25.1002 29.4556 25.9012 29.7311 26.5665 29.4077C27.2335 29.0835 27.5113 28.2799 27.1871 27.6129L25.9794 28.2C27.1871 27.6129 27.1868 27.6123 27.1865 27.6116L27.1842 27.6071L27.1804 27.5993L27.1696 27.5778C27.1611 27.561 27.1498 27.5391 27.1358 27.5125C27.1076 27.4592 27.0683 27.387 27.0173 27.2986C26.9155 27.1219 26.7666 26.8793 26.5671 26.593C26.1691 26.0218 25.5635 25.2674 24.7204 24.5133C23.5798 23.4932 22.0074 22.4783 19.9519 21.9213ZM16.5793 20.1428C18.8042 20.1428 20.6079 18.3391 20.6079 16.1142C20.6079 13.8893 18.8042 12.0856 16.5793 12.0856C14.3544 12.0856 12.5507 13.8893 12.5507 16.1142C12.5507 18.3391 14.3544 20.1428 16.5793 20.1428Z"
          fill="#484848"
        />
      </svg>
    </div>
  );
};

export const logoutSvg = () => {
  return (
    <svg
      width="30"
      height="21"
      viewBox="0 -5 13 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6707 20.0316C11.0748 20.6275 10.1086 20.6275 9.51266 20.0316L1.11975 11.6387C0.523813 11.0427 0.523813 10.0765 1.11975 9.48059L9.51266 1.08768C10.1086 0.491741 11.0748 0.491741 11.6707 1.08768C12.2667 1.68361 12.2667 2.64981 11.6707 3.24574L4.35685 10.5596L11.6707 17.8735C12.2667 18.4694 12.2667 19.4356 11.6707 20.0316Z"
        fill="#484848"
      />
    </svg>
  );
};
export const hamburgerSvg = () => {
  return (
    <svg viewBox="-5 1 100 80" width="40" height="40" fill="#484848">
      <rect y="15" width="70" height="10" rx="5" />
      <rect y="35" width="70" height="10" rx="5" />
      <rect y="55" width="70" height="10" rx="5" />
    </svg>
  );
};

export const closeSvg = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 2 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#484848"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#484848"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const switchSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="-4 -5 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 4V10H17"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 20V14H7"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51 9C4.01717 7.56678 4.87913 6.2854 6.01547 5.27542C7.1518 4.26543 8.52547 3.55976 10.0083 3.22426C11.4911 2.88875 13.0348 2.93434 14.4952 3.35677C15.9556 3.77921 17.2853 4.56471 18.36 5.64L23 10M1 14L5.64 18.36C6.71475 19.4353 8.04437 20.2208 9.50481 20.6432C10.9652 21.0657 12.5089 21.1112 13.9917 20.7757C15.4745 20.4402 16.8482 19.7346 17.9845 18.7246C19.1209 17.7146 19.9828 16.4332 20.49 15"
        stroke="#484848"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const makerSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.23535 15C7.9847 15 7.85938 14.8657 7.85938 14.5972V7.70068C7.85938 7.43213 7.9847 7.29785 8.23535 7.29785H9.79297C10.0007 7.29785 10.1493 7.44108 10.2388 7.72754L11.6943 12.438C11.7588 12.6421 11.8035 12.8104 11.8286 12.9429C11.8537 13.0718 11.8752 13.2257 11.8931 13.4048H11.9253C11.9432 13.2257 11.9647 13.0718 11.9897 12.9429C12.0148 12.8104 12.0596 12.6421 12.124 12.438L13.5796 7.72754C13.6691 7.44108 13.8177 7.29785 14.0254 7.29785H15.583C15.8337 7.29785 15.959 7.43213 15.959 7.70068V14.5972C15.959 14.8657 15.8337 15 15.583 15H14.7666C14.516 15 14.3906 14.8657 14.3906 14.5972V10.1606C14.3906 9.9458 14.3996 9.73096 14.4175 9.51611H14.3799L12.8276 14.5649C12.7381 14.855 12.5841 15 12.3657 15H11.4526C11.2342 15 11.0802 14.855 10.9907 14.5649L9.43848 9.51611H9.40088C9.41878 9.73096 9.42773 9.9458 9.42773 10.1606V14.5972C9.42773 14.8657 9.30241 15 9.05176 15H8.23535Z"
        fill="black"
      />
    </svg>
  );
};

export const personSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const earthSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.83 3.10999C13.8 3.54999 13.6 3.95998 13.3 4.27998C12.99 4.60998 12.53 4.72999 12.23 5.05999C12.02 5.29999 11.97 5.62998 12.03 5.93998C12.11 6.29998 12.36 6.56998 12.44 6.91998C12.52 7.27998 12.44 7.63998 12.46 8.00998C12.48 8.41998 12.52 8.83998 12.5 9.24998C12.47 10.03 11.97 10.73 11.26 11.04C10.96 11.17 10.61 11.25 10.4 11.51C10.2 11.75 10.18 12.09 10 12.34C9.8 12.64 9.38999 12.79 9.03999 12.68C8.69999 12.58 8.46999 12.28 8.17999 12.1C7.84999 11.9 7.45 11.82 7.06 11.81C6.65 11.8 6.23001 11.87 5.82001 11.93C5.02001 12.06 4.08999 12.2 3.39999 11.68"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.98 20.42C10.96 20.01 10.92 19.59 10.94 19.18C10.97 18.4 11.47 17.7 12.18 17.39C12.48 17.26 12.83 17.18 13.04 16.92C13.24 16.68 13.26 16.34 13.44 16.09C13.64 15.79 14.05 15.64 14.4 15.75C14.74 15.85 14.97 16.15 15.26 16.33C15.59 16.53 15.99 16.61 16.38 16.62C16.79 16.63 17.21 16.56 17.62 16.5C18.42 16.37 19.2079 16.1 19.8979 16.62"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const gridSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67999 10.36H5.32001C4.39001 10.36 3.64 9.60999 3.64 8.67999V5.32001C3.64 4.39001 4.39001 3.64001 5.32001 3.64001H8.67999C9.60999 3.64001 10.36 4.39001 10.36 5.32001V8.67999C10.36 9.60999 9.60999 10.36 8.67999 10.36Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.68 10.36H15.32C14.39 10.36 13.64 9.60999 13.64 8.67999V5.32001C13.64 4.39001 14.39 3.64001 15.32 3.64001H18.68C19.61 3.64001 20.36 4.39001 20.36 5.32001V8.67999C20.36 9.60999 19.61 10.36 18.68 10.36Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.67999 20.36H5.32001C4.39001 20.36 3.64 19.61 3.64 18.68V15.32C3.64 14.39 4.39001 13.64 5.32001 13.64H8.67999C9.60999 13.64 10.36 14.39 10.36 15.32V18.68C10.36 19.61 9.60999 20.36 8.67999 20.36Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.68 20.36H15.32C14.39 20.36 13.64 19.61 13.64 18.68V15.32C13.64 14.39 14.39 13.64 15.32 13.64H18.68C19.61 13.64 20.36 14.39 20.36 15.32V18.68C20.36 19.61 19.61 20.36 18.68 20.36Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
