import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Userfront from "@userfront/react";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  errorMessageAlert,
  createListingSuccessAlert,
} from "functions/functions";
import Step11 from "./Step1-1";
import Step12 from "./Step1-2";
import Step21 from "./Step2-1";
import Step22 from "./Step2-2";
import Step31 from "./Step3-1";
import Step32 from "./Step3-2";
import StepConfirm from "./StepConfirm";
import {
  Step11Validation,
  Step12Validation,
  Step21Validation,
  Step22Validation,
  Step31Validation,
  Step32Validation,
} from "./Validation";
import "./FormSteps.css";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function CreateListing() {
  const [currentStep, setStep] = useState(0); // index of current step
  const fields = {
    title: "",
    description: "",
    material: "",
    condition: "",
    quantity: "",
    total_price: "",
    pickup_date_start: "",
    pickup_date_end: "",
    location: "",
    pickup_instructions: "",
    listing_images: [],
  };
  const [newLocation, setLocation] = useState({
    line1: "",
    line2: "",
    level2: "",
    level1: "",
    postal: "",
  });
  const [answers, setAnswers] = useState(fields);
  const [errors, setErrors] = useState(fields);
  const [loading, setLoading] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);

  const navigate = useNavigate();
  // pass in the full body of the new listing
  const submitListing = () => {
    setLoading(true);
    const formData = new FormData();
    // Append other form fields
    formData.append("condition", answers.condition);
    formData.append("description", answers.description);
    formData.append("pickup_date_end", answers.pickup_date_end);
    formData.append("location", answers.location);
    formData.append("material", answers.material);
    formData.append("title", answers.title);
    formData.append("quantity", answers.quantity);
    formData.append("pickup_date_start", answers.pickup_date_start);
    formData.append("unit_price", answers.total_price);
    formData.append("pickup_instructions", answers.pickup_instructions);
    answers.listing_images.forEach((image) => {
      formData.append("listing_images[]", image.file);
    });

    // Append other required fields
    formData.append("maker", Userfront.user.userUuid);
    // ASK ABOUT THIS
    // formData.append("junioradmin", Userfront.user.userUuid);
    formData.append("name", answers.title);
    axios
      .post(`${API_URL}listings/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        createListingSuccessAlert();
        navigate(`/listings/${res.data.listing_id}`);
      })
      .catch(() => {
        setLoading(false);
        errorMessageAlert("failed to create your listing");
      });
  };

  const editAnswers = (field, value) => {
    setAnswers((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const autofillAnswers = (updatedAnswers) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ...updatedAnswers,
    }));
    setAutoFilled(true);
  };

  const clearAutofill = () => {
    const updatedFields = {
      title: "",
      description: "",
      material: "",
      condition: "",
      quantity: "",
      total_price: "",
      pickup_date_start: "",
      pickup_date_end: "",
      location: "",
      pickup_instructions: "",
      listing_images: [],
    };
    autofillAnswers(updatedFields);
    setAutoFilled(false);
  };

  const steps = [
    <Step11
      key="s1-1"
      answers={answers}
      errors={errors}
      editAnswers={editAnswers}
      autofillAnswers={autofillAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step11Validation}
      autoFilled={autoFilled}
      clearAutofill={clearAutofill}
    />,
    <Step12
      key="s1-2"
      answers={answers}
      errors={errors}
      editAnswers={editAnswers}
      autofillAnswers={autofillAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step12Validation}
    />,
    <Step21
      key="s2-1"
      answers={answers}
      errors={errors}
      newLocation={newLocation}
      setLocation={setLocation}
      editAnswers={editAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step21Validation}
      autoFilled={autoFilled}
    />,
    <Step22
      key="s2-1"
      answers={answers}
      errors={errors}
      newLocation={newLocation}
      setLocation={setLocation}
      editAnswers={editAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step22Validation}
      autoFilled={autoFilled}
    />,
    <Step31
      key="s3"
      answers={answers}
      errors={errors}
      editAnswers={editAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step31Validation}
    />,
    <Step32
      key="s3"
      answers={answers}
      errors={errors}
      editAnswers={editAnswers}
      setErrors={setErrors}
      setStep={setStep}
      isValid={Step32Validation}
    />,
    <StepConfirm
      key="sreview"
      answers={answers}
      setStep={setStep}
      submit={submitListing}
      loading={loading}
    />,
  ];

  const stepTitles = [
    "Step 1: Listing Details",
    "Step 1: Listing Details",
    "Step 2: Time and Place",
    "Step 2: Time and Place",
    "Step 3: Presentation",
    "Step 3: Presentation",
    "Step 4: Confirm your Listing",
  ];

  return (
    <>
      <div className="create-listing-topper">
        <div className="row">
          <div className="title-label left"> {stepTitles[currentStep]}</div>
        </div>
        <ProgressBar
          bgColor="#ff7708"
          borderRadius="0px 10px 10px 0px"
          height="12px"
          isLabelVisible={false}
          completed={((currentStep + 1) / stepTitles.length) * 100}
        />
      </div>
      <div className="row">{steps[currentStep]}</div>
    </>
  );
}

export default CreateListing;
